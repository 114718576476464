@font-face {
    font-family: "Open-Sans";
    src: local("Open-Sans"), url("./fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf") format("truetype");
} 
@font-face {
    font-family: "EB-Garamond"; /* Closest font to Silian Rail iykyk */
    src: local("EB-Garamond"), url("./fonts/EB_Garamond/EBGaramond-Italic-VariableFont_wght.ttf"), format(truetype);

}

:root {
    /*Color Schema */
    --primaryColor: #7b5fc7;
    --secondaryColor: #000000;
    --tertiaryColor: #39383d;
    --white: #ffffff;
    --width: 40%;
}

* {
    margin: 0px;
}


html, body {
    /* font-size: 20px; */
    background-color: var(--tertiaryColor);
    font-family: Open-Sans;
    letter-spacing: .140rem;
    scroll-behavior: smooth;
    overflow: auto;
}

#root {
    display:flex;
    flex-direction: column;
    align-items: center;
}

.App {
    max-width: 1200px;
}

.sectional{ 
    min-width: 100%;
    min-height: max(50vh, 500px);
    padding: 15vh 0vh;
}

.sectional > h2 {
    margin-bottom: 3vh;
}

#highlight{
    color: var(--primaryColor);
}

.loading {
    position: absolute;
    width: 100%;
    height:100%;
    z-index: 5;
    background-color: var(--primaryColor);
}

.loading > h2 {
    color: white;
}

.show-loading {
    opacity: 1;
}
  
.hide-loading {
    opacity: 0;
    z-index: -20;
}

.z-index-1{
    z-index: 1; 
}
  
.w100perc{
width:100%
}

.h100perc{
height:100%
}

/* General css */
.dont-wrap-text {
text-wrap: nowrap;
}

.color-white {
color: white;
}

.font-small {
font-size: .7rem; 
}