.blog-item{
    width: 90%;
    background-color: grey;
    border-radius: 10px;
    padding: 3vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blog-item > h5{
    margin: 0px
}
@media (max-width: 500px){
    .blog-item > h2{
        font-size: 1rem;
    }
    .blog-item p {
        font-size: .75rem;
    }
     


}