.main-window {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 50px; */
}
@media (max-width: 1025px){
    .nav-section{
        align-self: flex-end;
    }
}

.item-section-1 {
    color: white;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 90%;
    padding: 30px;
    border-radius: 1em;
    margin-top: 4rem;
    gap: 4vh;
}