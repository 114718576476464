.item-image {
    width: 200px;
    height: 200px;
    display: flex;
    flex: 1 9;
    object-fit: fill;
}

.img-text-hz-card {
    margin: 25px 60px 30px;
}

.img-text-hz-card > div > h4 {
    margin: 20px 0px;
}

.img-text-hz-card > div > p {
    font-size: smaller;
}

.img-text-hz-card > div {
    display: flex;
    flex: 9 1;
    width: 90%;
    margin: 15px 40px;
}

