
@keyframes slide-in {
    0%      {opacity: 0; margin-bottom:-35%;}
    25%     {opacity: 0.25; margin-bottom:-17.5%;}
    50%     {opacity: 0.5; margin-bottom:-8.75%;}
    75%     {opacity: 0.75; margin-bottom:-4.375%;}
    100%    {opacity: 1; margin-bottom:0%;}
}

@keyframes fade-in {
    0%      {opacity: 0.00;}
    25%     {opacity: 0.25;}
    50%     {opacity: 0.50;}
    75%     {opacity: 0.75;}
    100%    {opacity: 1.00;}
} 

@keyframes fade-out {
    0%      {opacity: 1.00;}
    25%     {opacity: 0.75;}
    50%     {opacity: 0.50;}
    75%     {opacity: 0.25;}
    100%    {opacity: 0.00;}
}

@keyframes blink{
    0%      {opacity: 0;}
    100%    {opacity: 1;}
}

.my-pic{
    width: 400px;
    height: 400px;
    object-fit: cover;
    border-radius: 50%;
    

}

.home-container{
    display: flex;
    /* width: 100%; */
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.home-info-container {
    display: flex;
    width: 100%;
    height: 100%;
    /* margin: auto; */
    flex-direction: row;
    /* min-width: fit-content; */
    align-items: center;
}
@media (max-width: 1025px) {
    .home-info-container {
        flex-direction: column;
        align-items: center;   
    }
}


.three-d-container{
    position: relative;
    display: flex;
    flex: 6 4;
    width: 100%;
}

.three-d {
    position: relative;
    /* flex: 1 1; */
    width: 100%;
}

canvas {
    overflow-clip-margin: content-box 30px;
    aspect-ratio: auto;
}

.greetings-flex{
    /* flex: 4 6; */
    width: 400px;
    /* padding: 10px 50px; */
    text-align: center;
}

.greetings-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 5%; */
    text-align: center;
    /* white-space: nowrap;  */
    flex-wrap: nowrap;
}
@media (max-width: 450px) {
    .greetings-box{
        white-space: pre-wrap;
    }
}

.greetings-box i{
    color: var(--primaryColor);
    font-size: 1.1rem;
}

.greetings-flex h1{
    font-size: 1.75rem;
}
@media (max-width: 450px) {
    .greetings-flex h1{
        font-size: 1.5rem;
    }
}

.greetings-box div{
    font-size: .9rem;
    text-align: center;
}
@media (max-width: 450px) {
    .greetings-box div {
        font-size: .8rem;
        width: 60%;
    }
}

.stackPng {
    width: 60px;
    margin: 3vw;
    aspect-ratio: 16/14;
}
.stackPngSQL{
    width: 9%;
    margin: 3vw;
    aspect-ratio: 16/14;
}


.cursor {
    /* display: inline-block; */
    /* overflow: none; */
    width: 4px;
    background-color: #FFFFFF;
    margin-left: 3px;
    animation: blink .7s steps(2) infinite;
    transform: rotate(0deg)
}