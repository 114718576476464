/*----------Start of Nav----------*/
.container-nav {
    margin-top: 40px;
    width: 100%;
    height: 60px;
}
.navigation-links{
    color: var(--white);
    font-size: .75rem;
    text-decoration: none;
}
.navigation-links:hover{
    color: var(--primaryColor);
}

.mobile-sandwich{
    position: fixed;
    height: 2.5rem;
    right: 10vw;
    z-index: 12;
}


.mobile-sandwich-1,
.mobile-sandwich-2,
.mobile-sandwich-3 {
    width: 2.5rem;
    height: .3rem;
    background-color: #FFFFFF;
    transition: 0.3s;
}


.change .mobile-sandwich-1 {
    transform: translate(0, 50%) rotate(-135deg);
}

.change .mobile-sandwich-2 {
    /* hides mid div */
    opacity: 0;
}

.change .mobile-sandwich-3 {
    transform: translate(0, -50%) rotate(-135deg);
}

#myNav {
    opacity: 1.0;
}
.overlay{
    height: 100vh;
    width: 0;
    position: fixed; 
    z-index: 3; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0,0,0); /* Black fallback color */
    background-color: rgba(0,0,0, 0.8); /* Black w/opacity */
    overflow-x: hidden; 
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
}
.overlay-content{
    position: relative;
    width: 100%;
    text-align: center; 
    display: flex;
    flex-direction: column;
}
.overlay-content a:hover {
    color: var(--primaryColor);
}
.overlay-content a {
    color: white;
    text-decoration: none;
    padding: 1rem;
    font-size: .7rem;
}
